@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html,
#root {
  @apply w-full h-full overflow-hidden;
}

body {
  @apply fixed;
  @apply pb-safe-bottom;
}

#root {
  @apply relative;
}

#root {
  @apply font-serif;
}

@font-face {
  font-family: "MinionPro";
  src: url(./assets/fonts/MinionPro/MinionPro-Regular.ttf) format("truetype");
}
